// ** React Imports
import React, { createContext, useContext, useState, useCallback, type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import {
  useIntl as useReactIntl,
  // FormattedMessage as ReactFormattedMessage,
  IntlProvider as ReactIntlProvider,
  type MessageDescriptor as ReactMessageDescriptor,
  // type PrimitiveType,
} from "react-intl"
// import { type FormatXMLElementFn, } from "intl-messageformat"
// ** Custom Components
// ** Hooks, context & utils
import useLocalStorage from "hooks/useLocalStorage"
// ** Conf & helpers
import { defaults, } from "conf/app"
import type { LanguageOptions, Languages, } from "conf/types/App"
import { flattenObject, } from "utility/helpers/object"
// ** Objects
// ** Styles
// ** Images

// ** FR resource
import frResource from "assets/locales/fr"
// ** EN resource
import enResource from "assets/locales/en"

export type Namespace = "app"  | "menu" | "messages" | "pathes"

const resources: Record<Languages, Record<Namespace, Record<string, string>>> = {
  fr: frResource,
  en: enResource,
}

type MessageDescriptor = ReactMessageDescriptor & {
  nameSpace?: Namespace
}

export type TType = (
  _id: string,
  _options?: Omit<MessageDescriptor, "id">
  // options?: Omit<MessageDescriptor, "id"> & { values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>> }
) => string

const defaultNamespace: Namespace = "app"

// ** Menu msg obj
const translations = Object.keys(resources).reduce((accumulator, currentValue) => ({
  ...accumulator,
  ...{ [currentValue]: flattenObject(resources[currentValue as Languages]), },
}), {}) as Record<Languages, Record<string, string>>

// ** Browser Detection
const browserLanguage = navigator.language.substring(0, 2)
// const storedLanguage = localStorage.getItem("language")
// const defaultLanguage: Languages = storedLanguage !== null && storedLanguage in Object.keys(resources) ? storedLanguage as Languages : defaults.defaultLanguage


interface IntlContextType {
  changeLanguage: (_lng: Languages) => void
  translatePath: (_path: string, _nameSpace: Namespace) => string
  T: TType
  // formatMessage: React.FC<{
  //   id: string
  //   options?: {
  //     nameSpace: string
  //     defaultMessage?: string
  //   }
  // }>
  language: Languages
  options: LanguageOptions
  languages: readonly Languages[]
}


const IntlContext = createContext<IntlContextType | null>(null)

const IntlProvider: React.FC<{ children: ReactNode }> = ({ children, }) => {
  const [ savedLanguage, setSavedLanguage, ] = useLocalStorage<Languages>("language", browserLanguage in resources ? browserLanguage as Languages : defaults.defaultLanguage)

  const [ language, setLanguage, ] = useState(savedLanguage)
  const [ options, setOptions, ] = useState(defaults.languages[savedLanguage])
  const [ messages, setMessages, ] = useState(translations[savedLanguage])
  // const { formatMessage: formatMessageReactIntl, } = useReactIntl()

  const changeLanguage = useCallback((lang: Languages): void => {
    if (lang === language) return

    const revPathes: Record<string, string> = Object.keys(resources[language].pathes).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        ...{ [resources[language].pathes[currentValue]]: currentValue, },
      }),
      {}
    )

    const translatedPath = decodeURIComponent(window.location.pathname)
      .split("/")
      .map(partial => translations[lang][`pathes.${revPathes[partial]}`])
      .join("/")

    setLanguage(lang)
    setSavedLanguage(lang)
    setMessages(translations[lang])
    setOptions(defaults.languages[lang])
    try {
      window.history.replaceState({}, "", translatedPath)
    } catch (error) {/* empty */} // eslint-disable-line @typescript-eslint/no-unused-vars
    console.log("lang", lang) // eslint-disable-line no-console
    document.documentElement.lang = lang
    document.querySelector("html")?.setAttribute("lang", lang)
  }, [ language, ])

  const translatePath = (path: string, nameSpace: Namespace = "menu"): string => path.split("/").map(partial => (partial === "" ? "" : T(partial, { nameSpace, }))).join("/")

  const T: TType = (id, options) => {
    const { formatMessage, } = useReactIntl()

    return formatMessage({
      id: `${options?.nameSpace ?? defaultNamespace}.${id}`,
      defaultMessage: options?.defaultMessage ?? id,
      description: options?.description,
    })
    // return formatMessageReactIntl({
    //   id: `${options?.nameSpace ?? "app"}.${id}`,
    //   defaultMessage: options?.defaultMessage ?? id,
    // })
  }

  // const formatMessage = ({
  //   id,
  //   options,
  // }: {
  //   id: string
  //   options?: {
  //     nameSpace: string
  //     defaultMessage?: string
  //   }
  // }): JSX.Element => <ReactFormattedMessage />

  return (
    <IntlContext.Provider
      value={{
        changeLanguage,
        translatePath,
        T,
        // formatMessage,
        language,
        options,
        languages: Object.keys(defaults.languages) as readonly Languages[],
      }}
    >
      <ReactIntlProvider
        key={language}
        locale={language}
        messages={messages}
        defaultLocale={savedLanguage}
        onError={() => {}}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  )
}

const useIntl = (): IntlContextType => {
  const context = useContext(IntlContext)
  if (context === null) {
    throw new Error("useIntl must be used within a IntlProvider context")
  }
  return context
}

export { IntlProvider, useIntl, }
