// ** React Imports
import React, { lazy, Suspense, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Container, } from "react-bootstrap"
import { Outlet, } from "react-router-dom"
// ** Custom Components
import FallbackSpinner from "components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import { useLayout, } from "utility/context/Layout"
import { MenuProvider, } from "./menu/context/Menu"
// ** Conf & helpers
import { SideBarSize, TopBarTheme, } from "conf/types/Layout"
import { changeHTMLAttribute, } from "utility/helpers/layout"
// ** Objects
// ** Styles
// ** Images

const Topbar = lazy(async () => import("./topbar/Topbar"))
const Menu = lazy(async () => import("./menu/Menu"))
const Footer = lazy(async () => import("./Footer"))
const RightSidebar = lazy(async () => import("./RightSidebar"))

const VerticalLayout: React.FC = () => {
  const { settings, width, } = useLayout()

  useEffect(() => {
    if (width < settings.gridBreakpoints.md) {
      changeHTMLAttribute("data-sidenav-size", SideBarSize.SIDE_BAR_SIZE_FULL)
    } else if (width < settings.gridBreakpoints.lg) {
      changeHTMLAttribute("data-sidenav-size", SideBarSize.SIDE_BAR_SIZE_CONDENSED)
    } else {
      changeHTMLAttribute("data-sidenav-size", settings.sidebar.size)
    }
  }, [ settings.sidebar.size, width, ])

  const topbarDark = settings.topbar.theme !== TopBarTheme.TOP_BAR_THEME_LIGHT

  return (
    <MenuProvider>
      <div className="wrapper">
        <Suspense fallback={<FallbackSpinner />}>
          <Topbar topbarDark={topbarDark} />
        </Suspense>

        <Suspense fallback={<FallbackSpinner />}>
          <Menu />
        </Suspense>

        <div className="content-page">
          <div className="content">
            <Suspense fallback={<FallbackSpinner />}>
              <Container fluid>
                <Outlet />
              </Container>
            </Suspense>
          </div>
          <Suspense fallback={<FallbackSpinner />}>
            <Footer />
          </Suspense>
        </div>

        <Suspense fallback={<FallbackSpinner />}>
          <RightSidebar />
        </Suspense>
      </div>
    </MenuProvider>
  )
}

export default VerticalLayout
