// ** React Imports
import React, { StrictMode, Suspense, } from "react"
// ** Store & Actions
// ** Third Party Components
import ReactDOM from "react-dom/client"
import { ErrorBoundary, } from "react-error-boundary"
// ** Custom Components
import App from "./App"
import ErrorFallback from "components/errorBoundary/ErrorBoundary"
import FallbackSpinner from "components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import { AppProvider, } from "utility/context/App"
import { AuthProvider, } from "utility/context/Auth"
import { LayoutProvider, } from "utility/context/Layout"
import { IntlProvider, } from "utility/context/Internationalization"
import { NotificationProvider, } from "utility/context/Notification"
// ** Conf & helpers
// ** Objects
// ** Styles
import "react-datepicker/dist/react-datepicker.min.css"
import "animate.css"
import "./assets/scss/Saas.scss"
// import "layouts/scss/style.scss"
// ** Images

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<FallbackSpinner />}>
        <AppProvider>
          <AuthProvider>
            <IntlProvider>
              <LayoutProvider>
                <NotificationProvider>
                  <App />
                </NotificationProvider>
              </LayoutProvider>
            </IntlProvider>
          </AuthProvider>
        </AppProvider>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>
)
