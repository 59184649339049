// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

export default {
  app: {
    all: "Tous",
    backToHome: "Retour à l'accueil",
    backToLogin: "Retour à <a>l'identification</a>",
    boxed: "Encadré",
    calendar: "Agenda",
    cancel: "Annuler",
    color: "Couleur",
    compact: "Compact",
    condensed: "Condensé",
    confirmation: "Confirmation",
    creative: "Créatif",
    dark: "Sombre",
    default: "Défaut",
    detached: "Détaché",
    dontSave: "Ne pas enregistrer",
    display: "Afficher",
    email: "Email",
    emailSent: "Email envoyé",
    enterYourEmail: "Saissisez votre email",
    enterYourPassword: "Saissisez votre mot de passe",
    fixed: "Ancré",
    fluid: "Fluide",
    forgotYourPassword: "Mot de passe oublié",
    fullLayout: "Pleine page",
    fullScreen: "Plein écran",
    goToPage: "Aller à la page",
    horizontal: "Horizontal",
    hoverView: "Affichage au survol",
    layout: "Mise en page",
    layoutMode: "Type de mise en page",
    light: "Claire",
    login: "Identification",
    loginFormDescription: "Entrez votre adresse email et votre mot de passe pour accéder au panneau d'administration.",
    logout: "Déconnexion",
    mainMenu: "Menu principal",
    mainMenuColor: "Couleur du menu principal",
    mainMenuSize: "Taille du menu principal",
    mainMenuPosition: "Emplacement du menu principal",
    mainMenuUserInfo: "Info utilisateur du menu principal",
    max: "Max",
    min: "Min",
    modern: "Moderne",
    new: "Nouveau",
    of: "sur",
    pageNotFound: "Page introuvable",
    pageNotFoundDescription: "La page demandée n'a pas été trouvée. Vérifiez l'URL ou retournez à l'accueil.",
    password: "Mot de passe",
    primary: "Primaire",
    records: "enregistrements",
    reset: "Réinitialiser",
    resetPassword: "Réinitialiser votre mot de passe",
    resetPasswordDescription:
      "Saisissez votre email et nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe.",
    save: "Enregistrer",
    scrollable: "Défilable",
    search: "Rechercher",
    settings: "Configuration",
    settingsTitle: "<strong>Personnalisez</strong> la palette de couleurs globale, le menu de la barre latérale, etc.",
    signIn: "Identification",
    style: "Style",
    submit: "Valider",
    topBarColor: "Couleur de la barre supérieure",
    trigram: "Trigramme",
    vertical: "Vertical",
    width: "Largeur",
  },
  menu: {
    calendar: "Calendrier",
    dashboards: "Tableaux de bord",
    more: "Plus",
  },
  messages: {
    apiUnavailable: "API indisponible",
    autenticationFailed: "Echec de l'autentification",
    // cantDeactivateCompanyWithContacts: "Impossible de désactiver une société avec des contacts actifs",
    // cantDeactivateCmpanyWithSubsidarie: "Impossible de désactiver une société avec des filiales",
    // cantDeactivateContactWithTodos: "Impossible de désactiver un contact avec des tâches actives",
    // cantDeactivateUserWithContact: "Impossible de désactiver un compte avec des contacts actifs",
    // cantDeactivateUserWithTeam: "Impossible de désactiver un compte avec des membres dans son équipe",
    commandTimeout: "Délai d'attente dépassée",
    dataNotValid: "Données non valides",
    error: "Erreur",
    excelExportDone: "Export excel terminé",
    // existingEmail: "Email existant",
    // existingName: "Nom existant",
    // existingRegistrationID: "SIRET existant",
    // existingTitle: "Titre existant",
    // existingTrigram: "Trigramme existant",
    // existingUser: "Utilisateur existant",
    // existingValue: "Valeur existante",
    forbidden: "Commande interdite",
    // inactiveCompany: "Société désactivée",
    // inactiveParentCompany: "Société mère désactivée",
    inactiveUser: "Utilisateur désactivé",
    // integrityError: "Erreur d'intégrité",
    internalServerError: "Erreur interne au server",
    logoutConfirm: "Etes-vous sûr de vouloir vous déconnecter ?",
    methodNotAllowed: "Méthode non permise",
    modelNotFound: "Modèle introuvable",
    noDataToExport: "Pas de données à exporter",
    notAllowed: "Action non permise",
    notValidEntry: "Entrée non valide",
    objectNotFound: "Objet non trouvé",
    // pleaseEnterValidEmail: "Merci de saisir une adresse email valide",
    // pleaseEnterPassword: "Merci de saisir votre mot de passe",
    queryException: "Erreur lors de l'execution de la requête",
    requiredEntry: "Entrée requise",
    tokenBlacklisted: "Token blacklisté",
    tokenExpired: "Token expiré",
    tokenInvalid: "Token non valide",
    unauthorized: "Non autorisé",
    unknownCommand: "Commande inconnu",
    unknownUser: "Utilisateur inconnu",
    userModified: "Utilisateur modifié",
    // weakPassword: "Mot de passe trop faible",
  },
  pathes: {
    account: "compte",
    "forget-password": "mot-de-passe-oublie",
    login: "identification",
    register: "enregistrer",
    settings: "parametres",
    users: "utilisateurs",
  },
}
