// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


/**
 * Changes the body attribute
 */
export const changeHTMLAttribute = (attribute: string, value?: string | null): void => {
  if (value === undefined || value === null) document.getElementsByTagName("html")[0].removeAttribute(attribute)
  else document.getElementsByTagName("html")[0].setAttribute(attribute, value)
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
export const manageBodyClass = (cssClass: string, action = "toggle"): void => {
  switch (action) {
    case "add":
      document.body.classList.add(cssClass)
      break
    case "remove":
      document.body.classList.remove(cssClass)
      break
    default:
      document.body.classList.toggle(cssClass)
      break
  }
}
