// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type IntlRouteObject, } from "../types"
// ** Objects
// ** Styles
// ** Images

// lazy load all test views

// ** Tests
const Animation = lazy(async () => import("views/tests/Animation"))
const ApiRequest = lazy(async () => import("views/tests/ApiRequest"))
const Blank = lazy(async () => import("views/tests/Blank"))
const Form = lazy(async () => import("views/tests/Form"))
const Hooks = lazy(async () => import("views/tests/hooks/Hooks"))
const Modals = lazy(async () => import("views/tests/Modals"))
const Notifications = lazy(async () => import("views/tests/Notifications"))
const Rating = lazy(async () => import("views/tests/Rating"))
const Spinner = lazy(async () => import("views/tests/Spinner"))
const Stepper = lazy(async () => import("views/tests/stepper/Stepper"))
const BasicTables = lazy(async () => import("views/tests/tables/basic/Basic"))
const AdvancedTables = lazy(async () => import("views/tests/tables/advanced/Advanced"))
const AllFeatures = lazy(async () => import("views/tests/tables/AllFeatures"))

// const ArrayColumnGroups = lazy(async () => import("views/tests/array/ArrayColumnGroups"))
// const ArrayColumnFiltering = lazy(async () => import("views/tests/array/ArrayColumnFiltering"))
// const ArrayColumnOrdering = lazy(async () => import("views/tests/array/ArrayColumnOrdering"))

// auth
// const Login = lazy(async () => import("views/tests/auth/components/Login"))
// const PersistLogin = lazy(async () => import("views/tests/auth/components/PersistLogin"))
// const RequireAuth = lazy(async () => import("views/tests/auth/components/RequireAuth"))
// const Home = lazy(async () => import("views/tests/auth/components/Home"))



// ** Template / Apps
const CalendarApp = lazy(async () => import("views/template/apps/calendar/Calender"))
// const ChatApp = lazy(async () => import("views/template/apps/chat/Chat"))


// ** Template / Dashboard
const AnalyticsDashboard = lazy(async () => import("views/template/dashboard/analytics/AnalyticsDashboard"))
const CRMDashboard = lazy(async () => import("views/template/apps/crm/dashboard/Dashboard"))
const EcommerceDashboard = lazy(async () => import("views/template/dashboard/eCommerce/EcommerceDashboard"))
const EWalletDashboard = lazy(async () => import("views/template/dashboard/eWallet/EWalletDashboard"))
const ProjectDashboard = lazy(async () => import("views/template/dashboard/project/ProjectDashboard"))






// // - auth
// const Login = lazy(async () => await import("views/template/account/Login"))
// const Logout = lazy(async () => await import("views/template/account/Logout"))
// const Register = lazy(async () => await import("views/template/account/Register"))
// const Confirm = lazy(async () => await import("views/template/account/Confirm"))
// const ForgetPassword = lazy(async () => await import("views/template/account/ForgetPassword"))
// const LockScreen = lazy(async () => await import("views/template/account/LockScreen"))

// const Login2 = lazy(async () => await import("views/template/account/Login2"))
// const Logout2 = lazy(async () => await import("views/template/account/Logout2"))
// const Register2 = lazy(async () => await import("views/template/account/Register2"))
// const Confirm2 = lazy(async () => await import("views/template/account/Confirm2"))
// const ForgetPassword2 = lazy(async () => await import("views/template/account/ForgetPassword2"))
// const LockScreen2 = lazy(async () => await import("views/template/account/LockScreen2"))

// // - dashboard

// // apps
// const ProjectList = lazy(async () => await import("views/template/apps/projects/List/Projects"))
// const ProjectDetail = lazy(async () => await import("views/template/apps/projects/detail/ProjectDetail"))
// const ProjectGannt = lazy(async () => await import("views/template/apps/projects/gantt/ProjectGannt"))
// const ProjectForm = lazy(async () => await import("views/template/apps/projects/ProjectForm"))

// // crm
// const CRMProjects = lazy(async () => await import("views/template/apps/crm/projects/Projects"))
// const CRMManagement = lazy(async () => await import("views/template/apps/crm/management/Management"))
// const CRMClients = lazy(async () => await import("views/template/apps/crm/clients/Clients"))
// const CRMOrderList = lazy(async () => await import("views/template/apps/crm/orderList/OrderList"))

// // ecommece pages
// const EcommerceProducts = lazy(async () => await import("views/template/apps/ecommerce/Products"))
// const ProductDetails = lazy(async () => await import("views/template/apps/ecommerce/ProductDetails"))
// const Orders = lazy(async () => await import("views/template/apps/ecommerce/Orders"))
// const OrderDetails = lazy(async () => await import("views/template/apps/ecommerce/OrderDetails"))
// const Customers = lazy(async () => await import("views/template/apps/ecommerce/Customers"))
// const Cart = lazy(async () => await import("views/template/apps/ecommerce/Cart"))
// const Checkout = lazy(async () => await import("views/template/apps/ecommerce/checkout/Checkout"))
// const Sellers = lazy(async () => await import("views/template/apps/ecommerce/Sellers"))

// // email
// const Inbox = lazy(async () => await import("views/template/apps/email/Inbox"))
// const EmailDetail = lazy(async () => await import("views/template/apps/email/Detail"))

// // social
// const SocialFeed = lazy(async () => await import("views/template/apps/socialFeed/SocialFeed"))

// // tasks
// const TaskList = lazy(async () => await import("views/template/apps/tasks/list/TaskList"))
// const TaskDetails = lazy(async () => await import("views/template/apps/tasks/details/TaskDetails"))
// const Kanban = lazy(async () => await import("views/template/apps/tasks/board/Kanban"))

// // file
// const FileManager = lazy(async () => await import("views/template/apps/fileManager/FileManager"))

// // pages
// const Profile = lazy(async () => await import("views/template/profile/Profile"))
// const Profile2 = lazy(async () => await import("views/template/profile2/Profile2"))
// const ErrorPageNotFound = lazy(async () => await import("views/errors/PageNotFound"))
// const ErrorPageNotFoundAlt = lazy(async () => await import("views/template/error/PageNotFoundAlt"))
// const ServerError = lazy(async () => await import("views/template/error/ServerError"))

// // other
// const Invoice = lazy(async () => await import("views/template/other/Invoice"))
// const FAQ = lazy(async () => await import("views/template/other/FAQ"))
// const Pricing = lazy(async () => await import("views/template/other/Pricing"))
// const Maintenance = lazy(async () => await import("views/template/other/Maintenance"))
// const Starter = lazy(async () => await import("views/template/other/Starter"))
// const PreLoader = lazy(async () => await import("views/template/other/preLoader/PreLoader"))
// const Timeline = lazy(async () => await import("views/template/other/Timeline"))

// const Landing = lazy(async () => await import("views/template/landing/Landing"))

// // uikit
// const Accordions = lazy(async () => await import("views/template/uikit/Accordions"))
// const Alerts = lazy(async () => await import("views/template/uikit/Alerts"))
// const Avatars = lazy(async () => await import("views/template/uikit/Avatars"))
// const Badges = lazy(async () => await import("views/template/uikit/Badges"))
// const Breadcrumbs = lazy(async () => await import("views/template/uikit/Breadcrumb"))
// const Buttons = lazy(async () => await import("views/template/uikit/Buttons"))
// const Cards = lazy(async () => await import("views/template/uikit/Cards"))
// const Carousels = lazy(async () => await import("views/template/uikit/Carousel"))
// const Dropdowns = lazy(async () => await import("views/template/uikit/Dropdowns"))
// const EmbedVideo = lazy(async () => await import("views/template/uikit/EmbedVideo"))
// const Grid = lazy(async () => await import("views/template/uikit/Grid"))
// const ListGroups = lazy(async () => await import("views/template/uikit/ListGroups"))
// const Modals = lazy(async () => await import("views/template/uikit/Modals"))
// const Notifications = lazy(async () => await import("views/template/uikit/Notifications"))
// const Offcanvases = lazy(async () => await import("views/template/uikit/Offcanvas"))
// const Placeholders = lazy(async () => await import("views/template/uikit/Placeholders"))
// const Paginations = lazy(async () => await import("views/template/uikit/Paginations"))
// const Popovers = lazy(async () => await import("views/template/uikit/Popovers"))
// const Progress = lazy(async () => await import("views/template/uikit/Popovers"))
// const Ribbons = lazy(async () => await import("views/template/uikit/Ribbons"))
// const Spinners = lazy(async () => await import("views/template/uikit/Spinners"))
// const Tabs = lazy(async () => await import("views/template/uikit/Tabs"))
// const Tooltips = lazy(async () => await import("views/template/uikit/Tooltips"))
// const Typography = lazy(async () => await import("views/template/uikit/Typography"))
// const DragDrop = lazy(async () => await import("views/template/uikit/DragDrop"))
// const RangeSliders = lazy(async () => await import("views/template/uikit/RangeSliders"))
// const Ratings = lazy(async () => await import("views/template/uikit/Ratings"))

// // icons
// const Dripicons = lazy(async () => await import("views/template/icons/Dripicons"))
// const MDIIcons = lazy(async () => await import("views/template/icons/mdiIcons/MDIIcons"))
// const Unicons = lazy(async () => await import("views/template/icons/Unicons"))

// // forms
// const BasicForms = lazy(async () => await import("views/template/forms/Basic"))
// const FormAdvanced = lazy(async () => await import("views/template/forms/Advanced"))
// const FormValidation = lazy(async () => await import("views/template/forms/Validation"))
// const FormWizard = lazy(async () => await import("views/template/forms/Wizard"))
// const FileUpload = lazy(async () => await import("views/template/forms/FileUpload"))
// const Editors = lazy(async () => await import("views/template/forms/Editors"))

// // charts
// const ApexChart = lazy(async () => await import("views/template/charts/apex/ApexChart"))
// const ChartJs = lazy(async () => await import("views/template/charts/chartJs/ChartJs"))

// // tables
// const BasicTables = lazy(async () => await import("views/template/tables/Basic"))
// const AdvancedTables = lazy(async () => await import("views/template/tables/Advanced"))

// // widgets
// const Widgets = lazy(async () => await import("views/template/uikit/Widgets"))

// // maps
// const GoogleMaps = lazy(async () => await import("views/template/maps/GoogleMaps"))
// const VectorMaps = lazy(async () => await import("views/template/maps/VectorMaps"))

const testsRoutes: IntlRouteObject[] = [
  {
    path: "template",
    children: [
      {
        path: "account",
        handle: { allowedRoles: { admin: true, }, },
        children: [
          {
            path: "confirm-mail",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "confirm-mail2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "lock-screen",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "lock-screen2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "login",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "login2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "logout",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "logout2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "recover-password",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "recover-password2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "register",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "register2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
        ],
      },
      {
        path: "apps",
        handle: { allowedRoles: { admin: true, }, },
        children: [
          {
            path: "calendar",
            element: <CalendarApp />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "chat",
            element: <Blank />,
            // element: <ChatApp />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "crm",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "clients",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "management",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "orders",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "projects",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
            ],
          },
          {
            path: "ecommerce",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "checkout",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "customers",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "management",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "orders",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "order-details",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "product-details",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "products",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "sellers",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "shopping-cart",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
            ],
          },
          {
            path: "email",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "inbox",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "read",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
            ],
          },
          {
            path: "file",
            handle: { allowedRoles: { admin: true, }, },
            element: <Blank />,
          },
          {
            path: "projects",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "create",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "details",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "gantt",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "list",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
            ],
          },
          {
            path: "social",
            handle: { allowedRoles: { admin: true, }, },
            element: <Blank />,
          },
          {
            path: "tasks",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "details",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "kanban",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
              {
                path: "list",
                element: <Blank />,
                handle: { allowedRoles: { admin: true, }, },
              },
            ],
          },

        ],
      },

      {
        path: "dashboard",
        handle: { allowedRoles: { admin: true, }, },
        children: [
          {
            path: "analytics",
            element: <AnalyticsDashboard />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "crm",
            element: <CRMDashboard />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "e-wallet",
            element: <EWalletDashboard />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "ecommerce",
            element: <EcommerceDashboard />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "project",
            element: <ProjectDashboard />,
            handle: { allowedRoles: { admin: true, }, },
          },
        ],
      },
      {
        path: "error",
        handle: { allowedRoles: { admin: true, }, },
        children: [
          {
            path: "404",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "404-alt",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "500",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "maintenance",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
        ],
      },
      {
        path: "landing",
        handle: { allowedRoles: { admin: true, }, },
        element: <Blank />,
      },
      {
        path: "pages",
        handle: { allowedRoles: { admin: true, }, },
        children: [
          {
            path: "faq",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "invoice",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "preloader",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "pricing",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "profile",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "profile2",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "starter",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "timeline",
            element: <Blank />,
            handle: { allowedRoles: { admin: true, }, },
          },
        ],
      },
      {
        path: "ui",
        handle: { allowedRoles: { admin: true, }, },
        children: [
          {
            path: "base-ui",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "accordions",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "alerts",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "avatars",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "badges",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "breadcrumb",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "buttons",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "cards",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "carousel",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "dropdowns",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "embed",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "grid",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "listgroups",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "modals",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "notifications",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "offcanvas",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "placeholders",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "pagination",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "popovers",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "progress",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "ribbons",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "spinners",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "tabs",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "tooltips",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "links",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "typography",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "utilities",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "charts",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "apex",
                handle: { allowedRoles: { admin: true, }, },
                children: [
                  {
                    path: "area",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "bar",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "boxplot",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "bubble",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "candlestick",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "column",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "heatmap",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "line",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "mixed",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "pie",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "polararea",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "radar",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "radialbar",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "scatter",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "sparklines",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "timeline",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "treemap",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                ],
              },
              {
                path: "chartjs",
                handle: { allowedRoles: { admin: true, }, },
                children: [
                  {
                    path: "area",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "bar",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "line",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                  {
                    path: "other",
                    handle: { allowedRoles: { admin: true, }, },
                    element: <Blank />,
                  },
                ],
              },
              {
                path: "sparklines",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "extended",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "dragdrop",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "rangesliders",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "ratings",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "scrollbar",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "forms",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "advanced",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "basic",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "editors",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "upload",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "validation",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "wizard",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "icons",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "mdi",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "remix",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "unicons",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "maps",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "googlemaps",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "vectormaps",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "tables",
            handle: { allowedRoles: { admin: true, }, },
            children: [
              {
                path: "advanced",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
              {
                path: "basic",
                handle: { allowedRoles: { admin: true, }, },
                element: <Blank />,
              },
            ],
          },
          {
            path: "widgets",
            handle: { allowedRoles: { admin: true, }, },
            element: <Blank />,
          },
        ],
      },
    ],
  },


  {
    path: "tests",
    children: [
      {
        path: "animation",
        element: <Animation />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "api-request",
        element: <ApiRequest />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "blank",
        element: <Blank />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "form",
        element: <Form />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "hooks",
        element: <Hooks />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "modals",
        element: <Modals />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "notifications",
        element: <Notifications />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "rating",
        element: <Rating />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "spinner",
        element: <Spinner />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "stepper",
        element: <Stepper />,
        handle: { allowedRoles: { admin: true, }, },
      },
      {
        path: "tables",
        children: [
          {
            path: "basic",
            element: <BasicTables />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "advanced",
            element: <AdvancedTables />,
            handle: { allowedRoles: { admin: true, }, },
          },
          {
            path: "all-features",
            element: <AllFeatures />,
            handle: { allowedRoles: { admin: true, }, },
          },
          // {
          //   path: "column-groups",
          //   element: <ArrayColumnGroups />,
          //   handle: { allowedRoles: { admin: true, }, },
          // },
          // {
          //   path: "column-filtering",
          //   element: <ArrayColumnFiltering />,
          //   handle: { allowedRoles: { admin: true, }, },
          // },
          // {
          //   path: "column-ordering",
          //   element: <ArrayColumnOrdering />,
          //   handle: { allowedRoles: { admin: true, }, },
          // },
        ],
      },




      //     {
      //       path: "auth",
      //       handle: {
      //         publicRoute: true,
      //       },
      //       children: [
      //         {
      //           path: "login",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //             fullPage: true,
      //           },
      //         },
      //         {
      //           path: "login",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //             fullPage: true,
      //           },
      //         },

      //         {
      //           path: "page1",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //           },
      //         },
      //         {
      //           path: "page2",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //           },
      //         },
      //         // {
      //         //   path: "signin",
      //         //   element: <Login />,
      //         //   handle: {
      //         //     title: "login",
      //         //     publicRoute: true,
      //         //     fullPage: true,
      //         //   },
      //         // },
      //         // {
      //         //   path: "",
      //         //   element: <PersistLogin />,
      //         //   handle: {
      //         //     publicRoute: true,
      //         //   },
      //         //   children: [
      //         //     {
      //         //       path: "",
      //         //       element: <RequireAuth allowedRoles={[ 5150, ]} />,
      //         //       handle: {
      //         //         publicRoute: true,
      //         //       },
      //         //       children: [
      //         //         {
      //         //           path: "home",
      //         //           element: <Home />,
      //         //           handle: {
      //         //             publicRoute: true,
      //         //           },
      //         //         },
      //         //       ],
      //         //     },
      //         //   ],
      //         // },
      //       ],
      //     },
      //     {
      //       path: "api-request",
      //       element: <ApiRequest />,
      //       handle: {
      //         publicRoute: false,
      //         allowedRoles: { admin: true, },
      //       },
      //     },
      //     {
      //       path: "hooks",
      //       element: <Hooks />,
      //       handle: {
      //         publicRoute: false,
      //         allowedRoles: { admin: true, },
      //       },
      //     },
      //     {
      //       path: "stepper",
      //       element: <Stepper />,
      //       handle: {
      //         publicRoute: false,
      //         allowedRoles: { admin: true, },
      //       },
      //     },
    ],
  },
]

export default testsRoutes
