// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { RouterProvider, } from "react-router-dom"
// ** Custom Components
import useRouter from "router/useRouter"
// ** Hooks, context & utils
// import useAccount from "hooks/useAccount"
// import { useAuth, } from "utility/context/Auth"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


const App: React.FC = () => {
  // const { persist, connectedUser, } = useAuth()
  const { router, } = useRouter()

  // const { refreshToken, } = useAccount()

  // useEffect(() => {
  //   if (persist && connectedUser === null) refreshToken()
  // }, [])

  return <RouterProvider router={router} />
}

export default App
