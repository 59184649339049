// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

export default {
  app: {
    all: "All",
    backToHome: "Back to home",
    backToLogin: "Back to <a>log in</a>",
    boxed: "Boxed",
    calendar: "Calendar",
    cancel: "Cancel",
    color: "Color",
    compact: "Compact",
    condensed: "Condensed",
    confirmation: "Confirmation",
    creative: "Creative",
    dark: "Dark",
    default: "Default",
    detached: "Detached",
    display: "Display",
    dontSave: "Don't save",
    email: "Email",
    emailSent: "Email sent",
    enterYourEmail: "Enter your email",
    enterYourPassword: "Enter your password",
    fixed: "Fixed",
    fluid: "Fluid",
    forgotYourPassword: "Forgot your password",
    fullLayout: "Full layout",
    fullScreen: "Full screen",
    goToPage: "Go to page",
    horizontal: "Horizontal",
    hoverView: "Hover View",
    layout: "Layout",
    layoutMode: "Layout mode",
    light: "Light",
    login: "Login",
    loginFormDescription: "Enter your email address and password to access admin panel.",
    logout: "Logout",
    mainMenu: "Main menu",
    mainMenuColor: "Main menu color",
    mainMenuPosition: "Main menu position",
    mainMenuSize: "Main menu size",
    mainMenuUserInfo: "Main menu user info",
    max: "Max",
    min: "Min",
    modern: "Modern",
    new: "New",
    of: "of",
    pageNotFound: "Page not found",
    pageNotFoundDescription: "The page you were looking for doesn't exist. Check the URL or head back home.",
    password: "Password",
    primary: "Primary",
    records: "records",
    reset: "Reset",
    resetPassword: "Reset your password",
    resetPasswordDescription: "Enter your email and we'll send you an email with instructions to reset your password.",
    save: "Save",
    scrollable: "Scrollable",
    search: "Search",
    settings: "Settings",
    settingsTitle: "<strong>Customize</strong> the overall color scheme, sidebar menu, etc.",
    signIn: "Sign in",
    style: "Style",
    submit: "Submit",
    topBarColor: "Top bar color",
    trigram: "Trigram",
    vertical: "Vertical",
    width: "Width",
  },
  menu: {
    calendar: "Calendar",
    dashboards: "Dashboards",
    more: "More",
  },
  messages: {
    apiUnavailable: "API unavailable",
    autenticationFailed: "Autentication failed",
    // cantDeactivateCompanyWithContacts: "Impossible de désactiver une société avec des contacts actifs",
    // cantDeactivateCmpanyWithSubsidarie: "Impossible de désactiver une société avec des filiales",
    // cantDeactivateContactWithTodos: "Impossible de désactiver un contact avec des tâches actives",
    // cantDeactivateUserWithContact: "Impossible de désactiver un compte avec des contacts actifs",
    // cantDeactivateUserWithTeam: "Impossible de désactiver un compte avec des membres dans son équipe",
    commandTimeout: "Command timeout",
    dataNotValid: "Data not invalid",
    error: "Error",
    excelExportDone: "Excel export done",
    // existingEmail: "Email existant",
    // existingName: "Nom existant",
    // existingRegistrationID: "SIRET existant",
    // existingTitle: "Titre existant",
    // existingTrigram: "Trigramme existant",
    // existingUser: "Utilisateur existant",
    // existingValue: "Valeur existante",
    forbidden: "Forbidden commande",
    // inactiveCompany: "Société désactivée",
    // inactiveParentCompany: "Société mère désactivée",
    inactiveUser: "Inactive user",
    // integrityError: "Erreur d'intégrité",
    internalServerError: "Internal server error",
    logoutConfirm: "Are you sure you want to log out?",
    methodNotAllowed: "Mmethod not allowed",
    modelNotFound: "Model not found",
    noDataToExport: "No data to export",
    notAllowed: "Not allowed",
    notValidEntry: "Not valid entry",
    objectNotFound: "Object not found",
    // pleaseEnterValidEmail: "Merci de saisir une adresse email valide",
    // pleaseEnterPassword: "Merci de saisir votre mot de passe",
    queryException: "An error occured while executing the request",
    requiredEntry: "Required entry",
    tokenBlacklisted: "Token blacklisted",
    tokenExpired: "Token expired",
    tokenInvalid: "Token not valid",
    unauthorized: "Non allowed",
    unknownCommand: "Unknown command",
    unknownUser: "Unknown user",
    userModified: "User modified",
    // weakPassword: "Mot de passe trop faible",
  },
  pathes: {
    account: "account",
    "forget-password": "forget-password",
    login: "login",
    register: "register",
    settings: "settings",
    users: "users",
  },
}
