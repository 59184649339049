// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import logo from "assets/images/logo-sm.png"


interface FallbackSpinnerProps {
  className?: string
}

const FallbackSpinner: React.FC<FallbackSpinnerProps> = ({ className, }) => (
  <div className={classNames("loading-spinner", className)}>
    <div className="fallback-container">
      <img className="loading-logo" src={logo} alt="logo" />
      <div className="effect-1"></div>
      <div className="effect-2"></div>
      <div className="effect-3"></div>
    </div>
  </div>
)

export default FallbackSpinner
