// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Clipboard, } from "react-feather"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { MenuItemDescription, } from "./types/Menu"
import type { AtLeastOneElementArray, } from "./types/Helpers"
// ** Objects
// ** Styles
// ** Images

export const menuItems: AtLeastOneElementArray<MenuItemDescription> = [
  {
    label: "Blank",
    url: "/tests/blank",
    icon: <Clipboard />,
    badge: { variant: "danger", text: "new", gradient: true, },
  },
  {
    label: "Tests",
    isTitle: false,
    icon: "uil-folder-plus",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Api request",
        url: "/tests/api-request",
      },
      {
        label: "Form",
        url: "/tests/form",
      },
      {
        isDivider: true,
        allowedRoles: { admin: true, },
      },
      {
        label: "Hooks",
        url: "/tests/hooks",
      },
      {
        label: "Modals",
        url: "/tests/modals",
      },
      {
        label: "Notifications",
        url: "/tests/notifications",
      },
      {
        label: "Rating",
        url: "/tests/rating",
      },
      {
        label: "Spinner",
        url: "/tests/spinner",
      },
      {
        label: "Stepper",
        url: "/tests/stepper",
      },
      {
        label: "Tables",
        badge: { variant: "danger", text: "new", gradient: true, },
        children: [
          {
            label: "Basic",
            url: "/tests/tables/basic",
            badge: { variant: "danger", text: "new", gradient: true, },
          },
          {
            label: "Advanced",
            url: "/tests/tables/advanced",
          },
          {
            label: "All features",
            url: "/tests/tables/all-features",
          },
        ],
      },
    ],
  },


  {
    isDivider: true,
    allowedRoles: { admin: true, },
  },


  {
    label: "dashboards",
    isTitle: false,
    icon: "uil-home-alt",
    badge: { variant: "success", text: "5", },
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Analytics",
        url: "/template/dashboard/analytics",
      },
      {
        label: "Ecommerce",
        url: "/template/dashboard/ecommerce",
      },
      {
        label: "Projects",
        url: "/template/dashboard/project",
      },
      {
        label: "CRM",
        url: "/template/dashboard/crm",
      },
      {
        label: "E-Wallet",
        url: "/template/dashboard/e-wallet",
      },
    ],
  },
  {
    label: "Apps",
    isTitle: true,
    allowedRoles: { admin: true, },
  },
  {
    label: "calendar",
    isTitle: false,
    icon: "uil-calender",
    url: "/template/apps/calendar",
    allowedRoles: { admin: true, },
  },
  {
    label: "Chat",
    isTitle: false,
    icon: "uil-comments-alt",
    url: "/template/apps/chat",
    allowedRoles: { admin: true, },
  },
  {
    label: "CRM",
    isTitle: false,
    badge: { variant: "danger", text: "new", },
    icon: "uil-tachometer-fast",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Projects",
        url: "/template/apps/crm/projects",
      },
      {
        label: "Orders List",
        url: "/template/apps/crm/orders",
      },
      {
        label: "Clients",
        url: "/template/apps/crm/clients",
      },
      {
        label: "Management",
        url: "/template/apps/crm/management",
      },
    ],
  },
  {
    label: "Ecommerce",
    isTitle: false,
    icon: "uil-store",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Products",
        url: "/template/apps/ecommerce/products",
      },
      {
        label: "Products Details",
        url: "/template/apps/ecommerce/product-details",
      },
      {
        label: "Orders",
        url: "/template/apps/ecommerce/orders",
      },
      {
        label: "Order Details",
        url: "/template/apps/ecommerce/order-details",
      },
      {
        label: "Customers",
        url: "/template/apps/ecommerce/customers",
      },
      {
        label: "Shopping Cart",
        url: "/template/apps/ecommerce/shopping-cart",
      },
      {
        label: "Checkout",
        url: "/template/apps/ecommerce/checkout",
      },
      {
        label: "Sellers",
        url: "/template/apps/ecommerce/sellers",
      },
    ],
  },
  {
    label: "Email",
    isTitle: false,
    icon: "uil-envelope",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Inbox",
        url: "/template/apps/email/inbox",
      },
      {
        label: "Read Email",
        url: "/template/apps/email/read",
      },
    ],
  },
  {
    label: "Projects",
    isTitle: false,
    icon: "uil-briefcase",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "List",
        url: "/template/apps/projects/list",
      },
      {
        label: "Details",
        url: "/template/apps/projects/details",
      },
      {
        label: "Gantt",
        url: "/template/apps/projects/gantt",
        badge: { variant: "light", text: "new", },
      },
      {
        label: "Create Project",
        url: "/template/apps/projects/create",
      },
    ],
  },
  {
    label: "Social Feed",
    isTitle: false,
    icon: "uil-rss",
    url: "/template/apps/social",
    allowedRoles: { admin: true, },
  },
  {
    label: "Tasks",
    isTitle: false,
    icon: "uil-clipboard-alt",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "List",
        url: "/template/apps/tasks/list",
      },
      {
        label: "Details",
        url: "/template/apps/tasks/details",
      },
      {
        label: "Kanban Board",
        url: "/template/apps/tasks/kanban",
      },
    ],
  },
  {
    label: "File Manager",
    isTitle: false,
    icon: "uil-folder-plus",
    url: "/template/apps/file",
    allowedRoles: { admin: true, },
  },
  {
    label: "Custom",
    isTitle: true,
    allowedRoles: { admin: true, },
  },
  {
    label: "Pages",
    isTitle: false,
    icon: "uil-copy-alt",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Profile",
        url: "/template/pages/profile",
      },
      {
        label: "Profile 2",
        url: "/template/pages/profile2",
      },
      {
        label: "Invoice",
        url: "/template/pages/invoice",
      },
      {
        label: "FAQ",
        url: "/template/pages/faq",
      },
      {
        label: "Pricing",
        url: "/template/pages/pricing",
      },
      {
        label: "Maintenance",
        url: "/template/error/maintenance",
        target: "_blank",
      },
      {
        label: "Authentication",
        // url: "/template/account",
        children: [
          {
            label: "Login",
            url: "/template/account/login",
          },
          {
            label: "Login 2",
            url: "/template/account/login2",
          },
          {
            label: "Register",
            url: "/template/account/register",
          },
          {
            label: "Register 2",
            url: "/template/account/register2",
          },
          {
            label: "Logout",
            url: "/template/account/logout",
          },
          {
            label: "Logout 2",
            url: "/template/account/logout2",
          },
          {
            label: "Recover Password",
            url: "/template/account/recover-password",
          },
          {
            label: "Recover Password 2",
            url: "/template/account/recover-password2",
          },
          {
            label: "Lock Screen",
            url: "/template/account/lock-screen",
          },
          {
            label: "Lock Screen 2",
            url: "/template/account/lock-screen2",
          },
          {
            label: "Confirm Mail",
            url: "/template/account/confirm-mail",
          },
          {
            label: "Confirm Mail 2",
            url: "/template/account/confirm-mail2",
          },
        ],
      },
      {
        label: "Error",
        // url: "/template/error",
        children: [
          {
            label: "Error - 404",
            url: "/template/error/404",
          },
          {
            label: "Error - 404-alt",
            url: "/template/error/404-alt",
          },
          {
            label: "Error - 500",
            url: "/template/error/500",
          },
        ],
      },
      {
        label: "Starter Page",
        url: "/template/pages/starter",
      },
      {
        label: "With Preloader",
        url: "/template/pages/preloader",
      },
      {
        label: "Timeline",
        url: "/template/pages/timeline",
      },
    ],
  },
  {
    label: "Landing",
    isTitle: false,
    icon: "uil-globe",
    url: "/template/landing",
    target: "_blank",
    badge: { variant: "secondary", text: "new", },
    allowedRoles: { admin: true, },
  },
  {
    label: "Components",
    isTitle: true,
    allowedRoles: { admin: true, },
  },
  {
    label: "Base UI",
    isTitle: false,
    icon: "uil-box",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Accordions & Collapse",
        url: "/template/ui/base-ui/accordions",
      },
      {
        label: "Alerts",
        url: "/template/ui/base-ui/alerts",
      },
      {
        label: "Avatars",
        url: "/template/ui/base-ui/avatars",
      },
      {
        label: "Badges",
        url: "/template/ui/base-ui/badges",
      },
      {
        label: "Breadcrumb",
        url: "/template/ui/base-ui/breadcrumb",
      },
      {
        label: "Buttons",
        url: "/template/ui/base-ui/buttons",
      },
      {
        label: "Cards",
        url: "/template/ui/base-ui/cards",
      },
      {
        label: "Carousel",
        url: "/template/ui/base-ui/carousel",
      },
      {
        label: "Dropdowns",
        url: "/template/ui/base-ui/dropdowns",
      },
      {
        label: "Embed Video",
        url: "/template/ui/base-ui/embed",
      },
      {
        label: "Grid",
        url: "/template/ui/base-ui/grid",
      },
      {
        label: "List Group",
        url: "/template/ui/base-ui/listgroups",
      },
      {
        label: "Modals",
        url: "/template/ui/base-ui/modals",
      },
      {
        label: "Notifications",
        url: "/template/ui/base-ui/notifications",
      },
      {
        label: "Offcanvas",
        url: "/template/ui/base-ui/offcanvas",
      },
      {
        label: "Placeholders",
        url: "/template/ui/base-ui/placeholders",
      },
      {
        label: "Pagination",
        url: "/template/ui/base-ui/pagination",
      },
      {
        label: "Popovers",
        url: "/template/ui/base-ui/popovers",
      },
      {
        label: "Progress",
        url: "/template/ui/base-ui/progress",
      },
      {
        label: "Ribbons",
        url: "/template/ui/base-ui/ribbons",
      },
      {
        label: "Spinners",
        url: "/template/ui/base-ui/spinners",
      },
      {
        label: "Tabs",
        url: "/template/ui/base-ui/tabs",
      },
      {
        label: "Tooltips",
        url: "/template/ui/base-ui/tooltips",
      },
      {
        label: "Links",
        url: "/template/ui/base-ui/links",
      },
      {
        label: "Typography",
        url: "/template/ui/base-ui/typography",
      },
      {
        label: "Utilities",
        url: "/template/ui/base-ui/utilities",
      },
    ],
  },
  {
    label: "Extended UI",
    isTitle: false,
    icon: "uil-package",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Drag and Drop",
        url: "/template/ui/extended/dragdrop",
      },
      {
        label: "Range Sliders",
        url: "/template/ui/extended/rangesliders",
      },
      {
        label: "Ratings",
        url: "/template/ui/extended/ratings",
      },
      {
        label: "Scrollbar",
        url: "/template/ui/extended/scrollbar",
      },
    ],
  },
  {
    label: "Widgets",
    isTitle: false,
    icon: "uil-layer-group",
    url: "/template/ui/widgets",
    allowedRoles: { admin: true, },
  },
  {
    label: "Icons",
    isTitle: false,
    icon: "uil-streering",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Remix Icons",
        url: "/template/ui/icons/remix",
      },
      {
        label: "Material Design",
        url: "/template/ui/icons/mdi",
      },
      {
        label: "Unicons",
        url: "/template/ui/icons/unicons",
      },
    ],
  },
  {
    label: "Charts",
    isTitle: false,
    icon: "ri-bar-chart-line",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Apex Charts",
        // url: "/template/",
        children: [
          {
            label: "Area",
            url: "/template/ui/charts/apex/area",
          },
          {
            label: "Bar",
            url: "/template/ui/charts/apex/bar",
          },
          {
            label: "Bubble",
            url: "/template/ui/charts/apex/bubble",
          },
          {
            label: "Candlestick",
            url: "/template/ui/charts/apex/candlestick",
          },
          {
            label: "Column",
            url: "/template/ui/charts/apex/column",
          },
          {
            label: "Heatmap",
            url: "/template/ui/charts/apex/heatmap",
          },
          {
            label: "Line",
            url: "/template/ui/charts/apex/line",
          },
          {
            label: "Mixed",
            url: "/template/ui/charts/apex/mixed",
          },
          {
            label: "Timeline",
            url: "/template/ui/charts/apex/timeline",
          },
          {
            label: "Boxplot",
            url: "/template/ui/charts/apex/boxplot",
          },
          {
            label: "Treemap",
            url: "/template/ui/charts/apex/treemap",
          },
          {
            label: "Pie",
            url: "/template/ui/charts/apex/pie",
          },
          {
            label: "Radar",
            url: "/template/ui/charts/apex/radar",
          },
          {
            label: "RadialBar",
            url: "/template/ui/charts/apex/radialbar",
          },
          {
            label: "Scatter",
            url: "/template/ui/charts/apex/scatter",
          },
          {
            label: "Polar Area",
            url: "/template/ui/charts/apex/polararea",
          },
          {
            label: "Sparklines",
            url: "/template/ui/charts/apex/sparklines",
          },
        ],
      },
      {
        label: "ChartJS",
        // url: "/template/",
        children: [
          {
            label: "Area",
            url: "/template/ui/charts/chartjs/area",
          },
          {
            label: "Bar",
            url: "/template/ui/charts/chartjs/bar",
          },
          {
            label: "Line",
            url: "/template/ui/charts/chartjs/line",
          },
          {
            label: "Other",
            url: "/template/ui/charts/chartjs/other",
          },
        ],
      },
      {
        label: "Sparklines Charts",
        url: "/template/ui/charts/sparklines",
      },
    ],
  },
  {
    label: "Forms",
    isTitle: false,
    icon: "uil-document-layout-center",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Basic Elements",
        url: "/template/ui/forms/basic",
      },
      {
        label: "Form Advanced",
        url: "/template/ui/forms/advanced",
      },
      {
        label: "Validation",
        url: "/template/ui/forms/validation",
      },
      {
        label: "Wizard",
        url: "/template/ui/forms/wizard",
      },
      {
        label: "File Upload",
        url: "/template/ui/forms/upload",
      },
      {
        label: "Editors",
        url: "/template/ui/forms/editors",
      },
    ],
  },
  {
    label: "Tables",
    isTitle: false,
    icon: "uil-table",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Basic Tables",
        url: "/template/ui/tables/basic",
      },
      {
        label: "Advanced Tables",
        url: "/template/ui/tables/advanced",
      },
    ],
  },
  {
    label: "Maps",
    isTitle: false,
    icon: "uil-location-point",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Google Maps",
        url: "/template/ui/maps/googlemaps",
      },
      {
        label: "Vector Maps",
        url: "/template/ui/maps/vectormaps",
      },
    ],
  },
  {
    label: "Multi Levels",
    isTitle: false,
    icon: "uil-folder-plus",
    allowedRoles: { admin: true, },
    children: [
      {
        label: "Level 1.1",
        icon: "mdi mdi-numeric-1-box-outline",
        children: [
          {
            label: "Level 2.1",
            icon: "mdi mdi-numeric-1-box-outline",
            children: [
              {
                label: "Level 3.1",
                url: "/tests/blank",
                icon: "mdi mdi-numeric-1-box-outline",
              },
              {
                label: "Level 3.2",
                url: "/tests/blank",
                icon: "mdi mdi-numeric-2-box-outline",
              },
            ],
          },
          {
            label: "Level 2.2",
            url: "/tests/blank",
            icon: "mdi mdi-numeric-2-box-outline",
          },
        ],
      },
      {
        label: "Level 1.2",
        url: "/tests/blank",
        icon: "mdi mdi-numeric-2-box-outline",
      },
    ],
  },
]
