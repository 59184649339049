// ** React Imports
import React, { createContext, useContext, type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce, type ToastOptions, } from "react-toastify"
import { type CloseButtonProps, } from "react-toastify/dist/components/CloseButton"
import { useIntl as useReactIntl, } from "react-intl"
// ** Hooks, context & utils
// ** Conf & helpers
import { defaults, } from "conf/layout"
// ** Objects
// ** Styles
import "react-toastify/dist/ReactToastify.css"
// ** Images

export interface Options {
  autoClose?: false | number
  hideProgressBar?: boolean
  closeOnClick?: boolean
  closeButton?: false | ((_props: CloseButtonProps) => React.ReactNode) | React.ReactElement<CloseButtonProps>
  pauseOnHover?: boolean
  draggable?: boolean
  theme?: "light" | "dark" | "colored"
  transition?: "slide" | "zoom" | "flip" | "bounce"
}

const handdleOptions = (options?: Options): ToastOptions => {
  const transition = (() => {
    switch (options?.transition) {
      case "slide":
        return Slide
      case "zoom":
        return Zoom
      case "flip":
        return Flip
      case "bounce":
        return Bounce
      default:
        return Slide
    }
  })()

  return { ...{ theme: "light", }, ...options, ...{ transition, }, }
}

interface NotificationContextType {
  toast: (_message: ReactNode, _options?: Options) => void
  toastInfo: (_message: ReactNode, _options?: Options) => void
  toastSuccess: (_message: ReactNode, _options?: Options) => void
  toastWarn: (_message: ReactNode, _options?: Options) => void
  toastWarning: (_message: ReactNode, _options?: Options) => void
  toastError: (_message: ReactNode, _options?: Options) => void
}

const NotificationContext = createContext<NotificationContextType | null>(null)

const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children, }) => {
  const { formatMessage, } = useReactIntl()

  const toastDefault = (message: ReactNode, options?: Options): void => {
    toast(typeof message === "string" ? formatMessage({ id: `messages.${message}`, defaultMessage: message, }) : message, handdleOptions(options))
  }
  const toastInfo = (message: ReactNode, options?: Options): void => {
    toast.info(typeof message === "string" ? formatMessage({ id: `messages.${message}`, defaultMessage: message, }) : message, handdleOptions(options))
  }
  const toastSuccess = (message: ReactNode, options?: Options): void => {
    toast.success(typeof message === "string" ? formatMessage({ id: `messages.${message}`, defaultMessage: message, }) : message, handdleOptions(options))
  }
  const toastWarn = (message: ReactNode, options?: Options): void => {
    toast.warn(typeof message === "string" ? formatMessage({ id: `messages.${message}`, defaultMessage: message, }) : message, handdleOptions(options))
  }
  const toastWarning = (message: ReactNode, options?: Options): void => {
    toast.warning(typeof message === "string" ? formatMessage({ id: `messages.${message}`, defaultMessage: message, }) : message, handdleOptions(options))
  }
  const toastError = (message: ReactNode, options?: Options): void => {
    toast.error(typeof message === "string" ? formatMessage({ id: `messages.${message}`, defaultMessage: message, }) : message, handdleOptions(options))
  }

  return (
    <NotificationContext.Provider
      value={{
        toast: toastDefault,
        toastInfo,
        toastSuccess,
        toastWarn,
        toastWarning,
        toastError,
      }}
    >
      <ToastContainer className="p-3" {...defaults.toastOptions} />

      {children}
    </NotificationContext.Provider>
  )
}

const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext)
  if (context === null) {
    throw new Error("useNotification must be used within an NotificationProvider context")
  }
  return context
}

export { NotificationProvider, useNotification, }
