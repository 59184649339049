// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { Size, } from "conf/types/Layout"
import type { Role, } from "conf/types/User"
import type { MenuOptions, MenuItemDescription,  } from "conf/types/Menu"
import { LayoutType, } from "conf/types/Layout"
// ** Styles
// ** Objects
import { MenuItem, } from "./MenuItem"
// import { MenuItem, type Group, } from "./MenuItem"
// ** Images

export class Menu {
  children: MenuItem[] = []
  menuSettings: MenuOptions
  menuRef: React.RefObject<HTMLUListElement>
  // shrinkedItemCount: number = 0

  constructor(
    navigationMap: MenuItemDescription[],
    role: Role,
    layoutType: LayoutType,
    menuSettings: MenuOptions,
    menuRef: React.RefObject<HTMLUListElement>,
    moreLabel: string
    // size: Size
  ) {
    // this.layoutType = layoutType
    this.menuSettings = {
      ...menuSettings,
      ...{
        moreLabel: moreLabel,
      },
    }

    this.menuRef = menuRef

    // const showRootDivider = layoutType !== LayoutType.LAYOUT_HORIZONTAL || menuSettings.showRootDividerHorizontalLayout
    // const showRootTitles = layoutType !== LayoutType.LAYOUT_HORIZONTAL || menuSettings.showRootTitlesHorizontalLayout

    let index = 0
    navigationMap.forEach(item => {
      // if (item.isDivider === true && !showRootDivider) return
      // if (item.isTitle === true && !showRootTitles) return

      const childItem = new MenuItem(item, role, menuSettings.keyPrefix,  null, index)
      if (!childItem.isNull()) {
        this.children.push(childItem)
        index++
      }
    })
    // if (layoutType === LayoutType.LAYOUT_HORIZONTAL) this.reshape(size)
  }

  // private expand(): this {
  //   if (this.shrinkedItemCount > 0) {
  //     this.children.splice(
  //       this.children.length - 1,
  //       1,
  //       ...this.children[this.children.length - 1].children as MenuItem[]
  //     )
  //     this.shrinkedItemCount = 0
  //   }
  //   return this
  // }

  // private shrink(size: number): this {
  //   if (size !== 0 && this.children.length > size) {
  //     const moreItem = new MenuItem(
  //       {
  //         label: this.menuSettings.moreLabel,
  //         icon: "uil-ellipsis-h",
  //       } as MenuItemDescription,
  //       {},
  //       `${this.menuSettings.keyPrefix}_more`
  //     )
  //     moreItem.children = this.children.slice(size)

  //     this.children.splice(size, this.children.length, moreItem)
  //     this.shrinkedItemCount = size
  //   }

  //   return this
  // }

  // reshape(size: Size): this {
  //   return this.expand().shrink(this.menuSettings.maxRootCount[size])
  // }

  reshape(size: Size, filterRoot:boolean): MenuItem[] {
    const maxRootCount = this.menuSettings.behaviour[size].maxRootCount
    const children = filterRoot
      ? this.children.filter(item => 
        (!item.isDivider && !item.isTitle) ||
        (item.isDivider && this.menuSettings.behaviour[size].showRootDividerHorizontalLayout) ||
        (this.menuSettings.behaviour[size].showRootTitlesHorizontalLayout)
      )
      : this.children

    // Shrink the menu if needed
    if(maxRootCount !== 0 && children.length > maxRootCount) {
      const moreItem = new MenuItem(
        {
          label: this.menuSettings.moreLabel,
          icon: "uil-ellipsis-h",
        } as MenuItemDescription,
        {},
        `${this.menuSettings.keyPrefix}_more`
      )
      
      // this.shrinkedItemCount = this.children.length - maxRootCount - 1
      moreItem.children = children.slice(maxRootCount)
      
      children.splice(maxRootCount, children.length, moreItem)
    }

    return children
  }

  findActiveItems(pathname: string): string[] {
    for(const menuItem of this.children) {
      const activeItems = menuItem.findActiveItems(decodeURIComponent(pathname))
      if(activeItems.length > 0) return activeItems
    }
    return []
  }

  // defineItemsStatus(location: string): this {
  //   for (const child of this.children) {
  //     child.setStatus(location)
  //   }
  //   return this
  // }

  // resetItemsStatus(): this {
  //   this.children.forEach(item => item.resetStatus)
  //   return this
  // }

  // findAllParent = (menuItem: MenuItem, children: MenuItem[] = this.children): MenuItem[] => {

  //   // const re = new RegExp(`${this.menuSettings.keyPrefix}\d+`, "g")
  //   const re = new RegExp(`${this.menuSettings.keyPrefix}(_[0-9]+)+`, "")


  //   const numbers = (menuItem.key.match(re) ?? []).map(Number)
  //   // console.log(numbers) // eslint-disable-line no-console
  //   console.log(menuItem.key.match(re)) // eslint-disable-line no-console

  //   // if(menuItem.parentKey === null) return []
  //   // menuSettings.keyPrefix
  //   // console.log(menuItem.parentKey) // eslint-disable-line no-console 
  //   // if(menuItem.parentKey === null) return []

  //   // for(const child of children) {
  //   //   // Child is link item
  //   //   if (child.key === menuItem.parentKey) return [ child, ]

  //   //   if (child.children !== null){
  //   //     if(child.hasGroupChild) {
  //   //       // Child has sub group
  //   //       // for (const group of child.children as Group[]) {
  //   //       //   if (!("component" in group)) {
  //   //       //     for(const subChild of group.children) {
  //   //       //       if(subChild.children !== null) {
  //   //       //         const parents = this.findAllParent(menuItem, subChild.children as MenuItem[])
  //   //       //         if(parents.length > 0) return [ child, subChild, ...parents, ]
  //   //       //       }
  //   //       //     }
  //   //       //   }
  //   //       // }
  //   //     } else {
  //   //       // Child has sub items
  //   //       for (const subItem of child.children as MenuItem[]) {
  //   //         // console.log(subItem.children) // eslint-disable-line no-console
  //   //         const parents = this.findAllParent(menuItem, subItem.children as MenuItem[])
  //   //       //   // if(parents.length > 0) return [ subItem, ...parents, ]
  //   //       }
  //   //     }  
  //   //   }
  //   // }

  //   return []
  // }

  // static findMenuItem = (
  //   menuItems: MenuItem[] | undefined,
  //   menuItemKey: string | undefined
  // ): MenuItem | null => {
  //   if (menuItems !== undefined && menuItemKey !== undefined) {

  //     for (const menuItem of menuItems) {
  //       if (menuItem.key === menuItemKey) {
  //         return menuItem
  //       }
  //       // const found = Menu.findMenuItem(menuItems.children, menuItemKey)
  //       // if (found !== null) return found
  //     }


  //     // for (let i = 0; i < menuItems.length; i++) {
  //     //   if (menuItems[i].key === menuItemKey) {
  //     //     return menuItems[i]
  //     //   }
  //     //   const found = MenuObj.findMenuItem(menuItems[i].children, menuItemKey)
  //     //   if (found !== null) return found
  //     // }
  //   }
  //   return null
  // }
}
