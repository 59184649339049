// ** React Imports
import React, { type ReactNode, createContext, useContext, useState, useMemo, useRef, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { useIntl as useReactIntl, } from "react-intl"
import { useLocation, } from "react-router-dom"
// ** Custom Components
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
// ** Conf & helpers
import { useAuth, } from "utility/context/Auth"
import { useIntl, } from "utility/context/Internationalization"
import { useLayout, } from "utility/context/Layout"
import { menuItems as defaultMenuItems, } from "conf/menu"
import { MenuItemDescription, GroupDescription, } from "conf/types/Menu"
import { AtLeastOneElementArray, } from "conf/types/Helpers"
import { manageBodyClass, } from "utility/helpers/layout"
// import { LayoutType, } from "conf/types/Layout"
// ** Objects
import { Menu, } from "../objects/Menu"
import { MenuItem, } from "../objects/MenuItem"
// ** Styles
// ** Images

interface MenuContextType {
  menu: Menu
  activeMenuItems: string[]
  openMenuItems: string[]
  toggleMenu: (_menuItem: MenuItem, _show: boolean) => void
  mobileMenuOpen: boolean
  toggleMobileMenuOpen: () => void
}

const MenuContext = createContext<MenuContextType | null>(null)

const MenuProvider: React.FC<{ children: ReactNode }> = ({ children, }) => {
  const { connectedUser, } = useAuth()
  const { settings, size, } = useLayout()
  const menuRef = useRef<HTMLUListElement>(null)
  const { language, } = useIntl()
  const { formatMessage, } = useReactIntl()
  const translateMenuItems = (items: AtLeastOneElementArray<MenuItemDescription>): MenuItemDescription[] => {
    return items.map(item => (
      {
        ...item,
        ...(item.label !== undefined && {
          label: formatMessage({ id: `menu.${item.label}`, defaultMessage: item.label, }),
        }),
        ...(item.description !== undefined && {
          description: formatMessage({ id: `menu.${item.description}`, defaultMessage: item.description, }),
        }),
        ...(item.badge !== undefined && {
          ...item.badge,
          ...{
            text: formatMessage({ id: `app.${item.badge.text}`, defaultMessage: item.badge.text, }),
          },
        }),
        ...(item.url !== undefined && {
          url: item.url.split("/").map(partial => partial === "" ? "" : formatMessage({ id: `pathes.${partial}`, defaultMessage: partial, })).join("/"),
        }),
        ...("children" in item && (
          // Has sub group
          "cols" in item.children[0]
            ? {
              children: (item.children as GroupDescription[]).reduce<GroupDescription[]>((acc, group) => {
                // Has child component
                if("component" in group) {
                  return [
                    ...acc,
                    group,
                  ]
                }
                return [
                  ...acc,
                  {
                    ...group,
                    children: translateMenuItems(group.children),
                  } as GroupDescription,
                ]
              }, []),
            }
            : {
              children: translateMenuItems(item.children as AtLeastOneElementArray<MenuItemDescription>),
            }
        )),
      } as MenuItemDescription
    ))
  }

  const menu = useMemo<Menu>(() => {
    return new Menu(
      translateMenuItems(defaultMenuItems),
      connectedUser?.role ?? {},
      settings.layout.type,
      settings.menu,
      menuRef,
      formatMessage({ id: `menu.${settings.menu.moreLabel}`, defaultMessage: settings.menu.moreLabel, })
      // size
    )
  }, [ connectedUser, language, ])

  const location = useLocation()
 
  const toggleMenu = (menuItem: MenuItem, show: boolean): void => {
    if (show) {
      setOpenMenuItems(menuItem.path)
    } else {
      setOpenMenuItems(old => old.filter(key => !key.startsWith(menuItem.key)))
    }
  }

  const [ mobileMenuOpen, toggleMobileMenuOpen, , hideMobileMenu, ] = useToggle()

  const activeMenuItems = useMemo<string[]>(() => menu.findActiveItems(location.pathname), [ menu, location.pathname, ])
  const [ openMenuItems, setOpenMenuItems, ] = useState<string[]>([])

  useEffect(() => {
    setOpenMenuItems(activeMenuItems.slice(0, -1))
  }, [ activeMenuItems, ])

  useEffect(() => {
    hideMobileMenu()
  }, [ location, ])

  useEffect(() => {
    if(size === "lg" || size === "xl" || size === "xxl") hideMobileMenu()
  }, [ size, hideMobileMenu, ])

  useEffect(() => {
    /**
     * Open the menu when having mobile screen
     */
    manageBodyClass("sidebar-enable", mobileMenuOpen ? "remove" : "add")
  }, [ mobileMenuOpen, ])

  return (
    <MenuContext.Provider
      value={{
        menu,
        activeMenuItems,
        openMenuItems,
        toggleMenu,
        mobileMenuOpen,
        toggleMobileMenuOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

const useMenu = (): MenuContextType => {
  const context = useContext(MenuContext)
  if (context === null) {
    throw new Error("useMenuContext must be used within an MenuProvider")
  }
  return context
}

export { MenuProvider, useMenu, }
