// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface Default {
  baseUrl: string
  timeout: number
  responseType: "arraybuffer" | "document" | "json" | "text" | "stream"
  responseEncoding: string
  publicKey: string
}

const config: Default = {
  baseUrl: "https://testapi.perktek.com",
  timeout: 10,
  responseType: "json",
  responseEncoding: "utf8",
  publicKey:
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA6djQ7OZREVVmxsDqV3iU\n" +
    "lVgHMXVlma20lssrcWTm9BG+a5qBb5+9xy/QMzlsZOWHmlZlMe45eyjliTUT+XfL\n" +
    "6vQXV6bfOqnVS64/fzPrcxYLPzh2KroJ+u2qQLAD5fQ8YOskqMYL7x9hsjaTHyjB\n" +
    "uc5XDnzwBhdJO+KtKPgbUiH1s1hcbtJXeoV3kp08pCWd8vcJ+IfOnrFyHFlZX/Zb\n" +
    "JOqwxRRd/vtZAPkVS6O9ln47QYSs+pF/eIT8TgtrRUwIDsDqktGaykQ5G4aqLeOI\n" +
    "Dbugca3uPBnkEiQJtjNtdXEGhFhwzfRAXFE/mnqssXKyD5OKrAtKwE80emES7TgF\n" +
    "Aaoz7o1ovOMUTDCft/pWBpivMSBsIW0n4RdNVwwuv3rYAHyfhNwF0Ns+uXtf2Bqd\n" +
    "Wh+Q382TA/y/8XxjQE/eNjSpEhW/AulUNsEUobS1VoErCwbEzTMgOGh3kWGZzVfX\n" +
    "m3zylA+JjFDeQB7KfOwYjvAUqxq9wUx96xc7jLlxX8crb1t6s6ouRjyXvqgmMFM8\n" +
    "3CMn9sJYOPrgft9LsvLJzU1jbnq25FUfX0sb7VCjofy/SM1yuBZPlKfvOLkN/XfJ\n" +
    "n/QsGnnGKUD+uM7wDwaMUr2R4tocVtpTCCVVKMc7hmpgcKG3yXkQfb6n8Z+nhAlq\n" +
    "6hd1+a4VEIqHKyjQyVrikkECAwEAAQ==\n" +
    "-----END PUBLIC KEY-----\n",
}

export default config
