// ** React Imports
import React, { createContext, useContext, type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { defaults, } from "conf/app"
import type { Language, Languages, } from "conf/types/App"
// ** Objects
// ** Styles
// ** Images

interface AppContextType {
  companyID: number
  name: string
  defaultUrl: string
  defaultLanguage: Languages
  defaultPageTitle: string
  languages: Language
  offCanvasWidth: string
  googleMapsApiKey: string
  // ReactGA: typeof ReactGA
}

const AppContext = createContext<AppContextType | null>(null)

const AppProvider: React.FC<{ children: ReactNode }> = ({ children, }) => {
  const { companyID, name, defaultUrl, defaultLanguage, defaultPageTitle, languages, offCanvasWidth, googleMapsApiKey, } = defaults
  // const { name, defaultUrl, defaultLanguage, defaultPageTitle, languages, offCanvasWidth, googleMapsApiKey, ReactGA, } = defaults

  return (
    <AppContext.Provider
      value={{
        companyID,
        name,
        defaultUrl,
        defaultLanguage,
        defaultPageTitle,
        languages,
        offCanvasWidth,
        googleMapsApiKey,
        // ReactGA,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useApp = (): AppContextType => {
  const context = useContext(AppContext)
  if (context === null) {
    throw new Error("useApp must be used within an AppProvider context")
  }
  return context
}

export { AppProvider, useApp, }
